import React from 'react';

export default function NewsCategories({ categories, onCategoryClick }) {

    return <>
        <div className="news-categories">
            <h3 style={{ marginTop: 0 }}>PAGAL GIMNASTIKOS ŠAKĄ</h3>
            {categories.map((c, i) => <div style={{ display: 'block' }} onClick={() => onCategoryClick(c.name)} key={i}>
                {c.name} <span className="news-count">({c.articles && c.articles.length ? c.articles.length : 0})</span>
            </div>)}
        </div>
    </>
}
