import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../../components/layout/layout";
import SEO from "../../components/layout/seo";
import Articles from "../../components/articles/articles";
import NewsCategories from "../../components/news-categories";
import Header from "../../components/Header";
import BasicLayout from "../../components/BasicLayout";

const Naujienos = () => {
  const data = useStaticQuery(query);
  const categories = data.allStrapiCategory.nodes;
  const allNews = data.allStrapiArticle.edges;

  const [filter, setFilter] = useState([]);
  const [news, setNews] = useState(data.allStrapiArticle.edges);

  useEffect(_ => {
    setNews(getNews())
  }, [filter])

  let onCategoryClick = (e) => {
    if (filter.indexOf(e) >= 0) {
      setFilter([...filter.filter(f => f !== e)])
    } else {
      setFilter([...filter, e])
    }
  }

  let getNews = () => {
    let ret = [];
    if (filter?.length > 0)
      ret = allNews.filter(nev => filter.find(f => f == nev?.node?.category?.name));
    else {
      ret = allNews;
    }
    return ret;
  }

  return <Layout>
    <SEO
      seo={{
        metaTitle: "gimnastika.lt | Naujienos",
      }}
    />
    <Header name="Naujienos" />
    <BasicLayout
      left={
        <>
          <Articles articles={news} filter={filter} handleDelete={onCategoryClick} />
        </>}
      right={
        <>
          <NewsCategories categories={categories} onCategoryClick={onCategoryClick} />
        </>
      } />
  </Layout>
};


export default Naujienos;

const query = graphql`
  query {
    allStrapiCategory(sort: {fields: created_at, order: ASC}) {
      nodes {
        name
        slug
        articles {
          title
        }
      }
    }
    allStrapiArticle(sort: {fields: publishedAt, order: DESC}) {
      edges {
      node {
      strapiId
          slug
          title
          publishedAt(formatString: "LL", locale: "lt")
          image {
      childImageSharp {
      fixed(width: 700, height: 500) {
      src
    }
            }
          }
          category {
      name
            slug
          }
        }
      }
    }
  }
`;